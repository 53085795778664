import { version } from '$app/environment';
import { PUBLIC_SENTRY_FRONTEND_KEY } from '$env/static/public';
import * as Sentry from '@sentry/svelte';

Sentry.init({
    dsn: PUBLIC_SENTRY_FRONTEND_KEY, 
    release: version,
    environment: import.meta.env.DEV ? "dev" : "prod",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.DEV ? 1 : 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export async function handleError({ error, event }) {
    Sentry.captureException(error, { event });

    return {
        message: 'Whoops!',
    };
}
  
